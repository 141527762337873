<template>
  <div class="portfolio">
    <div class="main__statistic-tokens">
      <div class="main__statistic-title">
        {{ $t('dashboardInfo.portfolio.title') }}
      </div>
      <ul class="main__statistic-tokens_list">
        <li class="main__statistic-tokens_item" v-if="matic">
          <span class="main__statistic-tokens_item_amount">
            <BaseIcon :name="matic.iconName" verAlign />
            {{ matic?.label }}
          </span>
          {{ matic?.amount }}
          Matic
        </li>

        <li class="main__statistic-tokens_item">
          <span class="main__statistic-tokens_item_amount" v-if="dai">
            <BaseIcon :name="dai.iconName" verAlign />
            {{ dai?.label }}
          </span>
          {{ dai?.amount }}
          {{ dai?.label }}
        </li>

        <li class="main__statistic-tokens_item" v-if="forceCoin">
          <span class="main__statistic-tokens_item_amount">
            <BaseIcon :name="forceCoin.iconName" verAlign />
            {{ forceCoin.label }}
          </span>
          {{ forceCoin?.amount }}
        </li>

        <li
          class="main__statistic-tokens_item"
          v-for="(item, idx) in statTokensFiltered"
          :key="idx"
        >
          <span class="main__statistic-tokens_item_amount">
            <BaseIcon :name="item.iconName" />
            {{ item.label }}
          </span>
          <div class="main__statistic-tokens_swap_wrap">
          <ButtonDark
            v-if="isMyAccount"
            :text="$t('dashboardInfo.portfolio.button')"
            v-show="item.iconName === 'cryptoSFCold'"
            @event="swap"
            class="button--mr"
          />
          {{ item.amount }}
          {{ item.label }}
          </div>
        </li>
      </ul>
    </div>
    <!-- <div class="main__statistic-swap">
      <div class="main__statistic-swap-title"></div>
      <div class="main__statistic-swap-inner">
        <ButtonDark
          :text="$t('dashboardInfo.portfolio.button')"
          @event="swap"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import pageReadyByConnect from "@/mixin/pageReadyByConnect";
import BaseIcon from '@/components/UI/BaseIcon.vue'
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  components: {
    BaseIcon,
    ButtonDark,
  },
  props: {
    isMyAccount: {
      type: Boolean
    },
  },
  data() {
    return {
      forceCoin: {
        iconName: 'cryptoForceCoin',
        label: 'Forcecoin',
        amount: '-',
      },
    }
  },
  mixins: [pageReadyByConnect],
  
  emits: ['swap'],

  async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },

  computed: {
    ...mapGetters('crypto', ['getBalance', 'getAccount']),

    statTokens() {
      const res = []
      const coinValues = Object.values(this.getBalance).map((el) => +el)
      const coinNames = ['Matic', 'SFC old', 'SFC new', 'SFC2', 'DAI']
      coinValues.forEach((el, idx) => {
        res.push({
          iconName: `crypto${coinNames[idx].replace(/[' ']/gi, '')}`,
          label: coinNames[idx],
          amount: el,
        })
      })
      return res
    },
    matic() {
      return this.statTokens.find((el) => el.iconName.includes('cryptoMatic'))
    },
    dai() {
      return this.statTokens.find((el) => el.iconName.includes('cryptoDAI'))
    },
    statTokensFiltered() {
      return this.statTokens.filter(
        (el) =>
          !el.iconName.toLowerCase().includes('matic') &&
          !el.iconName.toLowerCase().includes('dai') &&
          !el.iconName.toLowerCase().includes('force coin') &&
          el.amount > 0
      )
    },
  },

  methods: {
    ...mapActions("crypto", ["getMetaPaymentBalancesFormatted"]),
    swap() {
      this.$emit('swap')
    },
    async pageReady() {
      try {
          const balances = await this.getMetaPaymentBalancesFormatted(this.getAccount);
          this.forceCoin.amount = balances[1];
      } catch (e) {
          console.log('getMetaPaymentBalancesFormatted error:', e);
      }
    }
  },

}
</script>
