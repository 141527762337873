<template>
  <div class="user-widget">
    <div class="user-widget-user">
      <div class="user-widget-user_avatar">
        <UiAvatar :user="user" />
      </div>
      <div class="user-widget-user_data">
        <!-- <p class="name">User Name</p> -->
        <p>
          {{ user.name }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(user.name)"
          />
        </p>
        <p>
          Wallet: {{ clientWalletShort }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(user.account)"
          />

          <span class="online" :class="{filled: isAccountInMetaCore}"></span>
        </p>
      </div>
    </div>
    <!-- <div class="user-widget-statistic">
      <p>{{ $t('dashboardInfo.userWidget.followers') }} <span>123</span></p>
      <p>{{ $t('dashboardInfo.userWidget.following') }} <span>1300</span></p>
    </div> -->
    <!-- <ButtonLight
      class="button-light--mr-bottom"
      :text="$t('dashboardInfo.userWidget.buttonLight')"
    />
    <ButtonDark
      class="button-dark--width"
      :text="$t('dashboardInfo.userWidget.buttonDark')"
    />
    <div class="social-links__wrap">
      <SocialLinks />
    </div> -->
    <!-- <div class="user-widget-status">
      {{ $t('dashboardInfo.userWidget.rStatus') }} <span>Ambasador</span>
    </div> -->
    <!-- <div class="user-widget-description">
      {{ $t('dashboardInfo.userWidget.aboutUser') }}
      from these domains
    </div> -->
    <template v-if="parent">
      <div class="user-widget-upline">
        <!-- <div class="user-widget-status">
          {{ $t('dashboardInfo.userWidget.rStatus') }}
        </div> -->
        <div class="user-widget-upline_title">
          {{ $t('dashboardInfo.userWidget.upline') }}
          <BaseIcon
            name="moreInfo"
            :content="$t(`account.upline_tooltip`)"
            v-tippy
          />
        </div>
        <div class="user-widget-upline_user">
          <div class="user__avatar">
            <UiAvatar :user="parent" />
          </div>
          <div class="user__data">
            <p> 
              {{ $t('search.label') }}: {{ parent.name }}
              <BaseIcon
                class="copy"
                name="copy"
                @event="handleClipboardCopy(parent.name)"
              />
            </p>
            <p>
              {{ $t('dashboardInfo.userWidget.wallet') }} {{ parentWalletShort }}
              <BaseIcon
                class="copy"
                name="copy"
                @event="handleClipboardCopy(parent.account)"
              />
            </p>
            <p>
              {{ $t('account.level') }}: {{ parent.activeLevel }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import BaseIcon from '@/components/UI/BaseIcon.vue'
import { nullEmptyHash } from '@/helpers/matrix'
import { isSameAccount } from '@/helpers/user'
import { sliceString } from '@/helpers/sliceString'
import { copyToClipboard } from '@/helpers/clipboard'

export default {
  components: {
    BaseIcon,
  },

  props: {
    user: Object,
  },

  data() {
    return {
      parent: null,
      isAccountInMetaCore: false,
    }
  },
  
  async mounted() {
    this.getUpline()
    this.isAccountInMetaCore = await this.isRegisteredInMeraCore(this.user.account);
  },
  
  computed: {
    ...mapGetters('crypto', [
      'getParent',
      'getMainContract',
      'getAccount',
    ]),

    isMyAccount() {
      return isSameAccount(this.getAccount, this.user.account)
    },
    clientWalletShort() {
      return sliceString(this.user.account)
    },
    parentWalletShort() {
      return sliceString(this.parent.account)
    },
  },

  methods: {
    ...mapActions('crypto', [
      'getProgramLevels',
      'isRegisteredInMeraCore'
    ]),
    ...mapActions('user', ['getUserByField']),
  
    async getUpline() {
      let parent = null
      if (this.isMyAccount) {
        parent = this.getParent
      } else {
        parent = await this.getMainContract.methods
          .parent(this.user.account)
          .call()
      }
      if (parent && nullEmptyHash(parent)) {
        const [lvlErr, levels] = await this.getProgramLevels(parent)
        const lastLevel = levels ? levels.findLast((x) => x.active) : null

        try {
          const { parentAcc } = await this.getUserByField({ account: parent });

          this.parent = {
            name: parentAcc && parentAcc.name,
            avatar: parentAcc && parentAcc.avatar,
            account: parent,
            activeLevel: lastLevel ? lastLevel.lvl + 1 : 0,
          }
        } catch {
          console.warn();
        }
      }
    },

    handleClipboardCopy(text) {
      if (!text) return
      // navigator.clipboard.writeText(text)
      copyToClipboard(text).then(() => {
          this.$toast.success(`${text} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },

  },

}
</script>
<style lang="scss" scoped>
.social-links__wrap {
  padding: 0 40px;
}
.button-dark--width {
  min-width: 100%;
}
.button-light--mr-bottom {
  margin-bottom: 8px;
}
.copy {
  margin-left: 2px;
  margin-top: 2px;
}
.online {
  margin-left: var(--indent-8);
  border-radius: 50%;
  height: 13px;
  width: 13px;
  border: 2px solid var(--color-purple);
  opacity: 0.6;
  cursor: pointer;
  &.filled {
    background-color: var(--color-purple);
  }
}
</style>
