<template>
  <div class="force-system">
    <div class="force-system__text">{{ $t('dashboardInfo.forceSystem.text') }}</div>
    <div class="force-system__btn-outer">
      <router-link :to="{ name: 'classic' }">
        <ButtonDark :text="$t('dashboardInfo.forceSystem.button')"/>
      </router-link>
    </div>
  </div>
</template>

<script>
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  components: {
    ButtonDark,
  },
}
</script>
<style lang="scss">
.frc-systems__button-dark {
  background: var(--color-white-10);
  min-width: 174px;
  min-height: 48px;
  border-radius: var(--indent-8);
  font-size: var(--font-14);
  line-height: var(--line-20);
  font-weight: var(--font-600);
  color: var(--color-white);
  margin-bottom: var(--indent-14);
  transition: background 500ms;

  &:hover {
    background: var(--color-white-30);
  }
}
</style>