<template>
    <div v-if="classicList" :class="['system-item__levels', guessForceSystem === true ? 'system-guessClassic' : 'system-classic']">
        <div v-for="item in classicList" :key="item.lvl" class="system-item__level"
            :class="[item.active === true ? 'active' : 'notActive']">
            <svg v-if="
                (item.lvl + 1) === 3 ||
                (item.lvl + 1) === 6 ||
                (item.lvl + 1) === 9 ||
                (item.lvl + 1) === 12
            " width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                    d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76532 48.2347 11.0475 50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.564 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
                <path
                    d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
                <path
                    d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.436 3.20136 18.9525 5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
            </svg>
            <svg v-else width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                    d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
                <path
                    d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
                <path
                    d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
                <path
                    d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
                <path
                    d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
                <path
                    d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                    :fill="[item.active === true ? '#FF9501' : 'white']" :fill-opacity="[item.active === true ? '' : '0.2']"
                    stroke="black" />
            </svg>
            <p>{{ item.lvl + 1 }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        classicList: Array,
        guessForceSystem: Boolean,
    },
}
</script>