<template>
  <section class="product section-offset">
    <div class="frc-systems-outer guessForceContainer">
      <div class="frc-systems" v-if="dataReady">
        <div class="frc-systems__item system-item">
          <div class="system-item__title">Tactile</div>
            <RealForceTactile :classicList="classicList" :guessForceSystem="guessForceSystem" />
          <div class="system-item__btns">
            <router-link
              :to="{ name: 'classicAllLevels'}"
            >
              <ButtonDark text="Show" classes="frc-systems__button-dark" />
            </router-link>
            <a href="./assets/files/pdf/Tactile_en.pdf" target="_blank">
              <p class="tooltip">
                About
                <svg
                  style="margin-left: var(--indent-8)"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="2"
                    stroke-linecap="square"
                    stroke-linejoin="bevel"
                  />
                </svg>
              </p>
            </a>
          </div>
        </div>
        <div class="frc-systems__item system-item guessSmallSize">
          <div class="system-item__title">Royalty NFT</div>
            <RealForceRoyaltyNft :nftLevels="nftLevels" :guessForceSystem="guessForceSystem" />
          <div class="system-item__btns">
            <router-link :to="{ name: 'nfts' }">
              <ButtonDark text="Show" classes="frc-systems__button-dark" />
            </router-link>
            <a href="./assets/files/pdf/Royalty_NFT_EN.pdf" target="_blank">
              <p class="tooltip">
                About
                <svg
                  style="margin-left: var(--indent-8)"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                    stroke="white"
                    stroke-opacity="0.3"
                    stroke-width="2"
                    stroke-linecap="square"
                    stroke-linejoin="bevel"
                  />
                </svg>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'

import pageReadyByConnect from '@/mixin/pageReadyByConnect'
import nftsApi from "@/api/nftsApi"
import RealForceTactile from "@/components/Dashboard/RealForceTactile.vue"
import RealForceRoyaltyNft from "@/components/Dashboard/RealForceRoyaltyNft.vue"
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  data() {
    return {
      account: null,
      userData: null,
      nfts: [],
      unitedVerse: false,
      guessForceSystem: true
    }
  },
  components: {
    RealForceTactile,
    RealForceRoyaltyNft,
    ButtonDark,
  },
  mixins: [pageReadyByConnect],
  async mounted() {
    if (this.isConnected) {
      await this.pageReady()
      await this.loadNfts()
    }
  },
  computed: {
    ...mapState('user', ['classicMatrix']),
    ...mapGetters('user', ['totalActiveLevels']),
    ...mapGetters('crypto', ['getAccount', 'getNftLvl']),

    dataReady() {
      return this.account && this.classicMatrix && this.classicMatrix.length
    },

    classicList() {
      return this.classicMatrix
    },
    
    nftLevels() {
      let lvls = []
      for (let nft of this.nfts) {
        lvls.push(this.getNftLvl(nft.contractAddress))
      }
      return lvls
    },
  },
  methods: {
  ...mapActions('crypto', ['getProgramLevels', 'checkRegistration']),
  ...mapActions('user', ['guessUser']),
  ...mapMutations('user', ['setClassicMatrix', 'updateClassicMatrixLevels']),
    // navigateTo(name) {
    //   this.$router.push({ name })
    // },
    async pageReady() {
      const [regErr, regParent] = await this.checkRegistration(true)
      if (regErr) {
        this.$swal(this.$t('errors.registrationError'))
        await this.$router.push({ name: 'signup' })
        return
      }

      const accountQuery = this.$route.query.account
      const targetAccount = accountQuery || this.getAccount
      

      try {
        let userResponse = await this.guessUser(targetAccount)
        if (!userResponse) throw new Error()

        this.id = userResponse.name
        this.account = userResponse.account
        this.userData = userResponse


        this.setClassicMatrix(userResponse.classic)

        // this.$toast.info(userResponse.classic)

        const [err, levels] = await this.getProgramLevels(userResponse.account)

        if (err) {
          this.$toast.error(`Fetch program level from blockchain error: ${err}`)
        }

        this.updateClassicMatrixLevels(levels)
      } catch (err) {
        this.$swal(this.$t('errors.userNotFound'))
        await this.$router.push({ name: 'dashboard' })
      }
    },
    async loadNfts() {
      let targetAccount
      const accountQuery = this.$route.query.account
      if (accountQuery) {
          targetAccount = accountQuery
      } else {
          targetAccount = this.getAccount
      }
      const userAcc = await this.guessUser(targetAccount)
      await nftsApi
          .nfts({
              owner: userAcc.account.toLowerCase(),
          })
          .then(({ data }) => {
              this.nfts = data
          })
          .catch((err) => {
              console.log("err", err)
          })
    }

  },

}
</script>

<style lang="scss">
.frc-systems__button-dark {
  background: var(--color-white-10);
  min-width: 174px;
  min-height: 48px;
  border-radius: var(--indent-8);
  font-size: var(--font-14);
  line-height: var(--line-20);
  font-weight: var(--font-600);
  color: var(--color-white);
  margin-bottom: var(--indent-14);
  transition: background 500ms;
  &:hover {
    background: var(--color-white-30);
  }
}
</style>