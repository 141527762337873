<template>
    <div v-if="nftLevels" :class="['system-item__levels', guessForceSystem === true ? 'system-guessClassic' : 'system-classic']">
        <div
            v-for="n in 8"
            :key="n"
            :class="[ nftLevels.includes(n - 1) ? 'active' : 'notActive']"
            class="system-item__level"
            >
            <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <rect width="56" height="56" rx="28" fill="black" />
            <path
                v-if="nftLevels.includes(n - 1)"
                d="M54 28C54 42.3594 42.3594 54 28 54C13.6406 54 2 42.3594 2 28C2 13.6406 13.6406 2 28 2C42.3594 2 54 13.6406 54 28ZM5.9 28C5.9 40.2055 15.7945 50.1 28 50.1C40.2055 50.1 50.1 40.2055 50.1 28C50.1 15.7945 40.2055 5.9 28 5.9C15.7945 5.9 5.9 15.7945 5.9 28Z"
                fill="#FF9501"
                stroke="black"
            />
            </svg>
            <p>{{ (n - 1) === 0 ? 'Just' : n - 1 }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        nftLevels: Array ,
        guessForceSystem: Boolean
    }
}
</script>