<template>
  <section class="dashboard">
    <Portfolio @swap="swap" :isMyAccount="isMyAccount"/>
    <ForceSystem v-if="isMyAccount === true"/>
    <!-- <InnerBalance v-if="isMyAccount === true" /> -->
    <GuessForceSystem v-if="isMyAccount === false" />

    <!-- <div class="dashboard-grid">
      <div class="dashboard-left__info">
        <div class="dashboard-logo">
          <UiAvatar :user="user" />
        </div>
        <div class="d-flex">
          <span class="dashboard-force-id">My Force ID</span>
          <h2 class="dashboard__title">{{ user.name }}</h2>
          <div class="dashboard__code">
            <span>{{ addressStrip }}</span>
            <button
              class="dashboard__copy-button btn-reset"
              type="button"
              @click="handleClipboardCopy(user.account)"
            >
              <svg-vue icon="clipboard" class="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      <div class="dashboard-item">
        <header
          class="dashboard-item__header dashboard-item__header--space-between"
        >
          <p class="dashboard-item__name">{{ $t('account.myLink') }}</p>
          <button
            class="dashboard-link__copy btn-reset"
            type="button"
            @click="handleClipboardCopy(`https://${refLink}`)"
          >
            <svg-vue icon="clipboard" class="w-5 h-5" />
          </button>
        </header>
        <div class="dashboard-link">
          <a :href="'//' + refLink" target="_blank">{{
            refLink.substr(0, 27) + '...'
          }}</a>
        </div>
        <p class="dashboard-item__share">
          {{ $t('account.linkTitle') }}
        </p>
      </div>
      <div class="dashboard-item">
        <template v-if="parent">
          <header
            class="dashboard-item__header dashboard-item__header--space-between"
          >
            <div class="dashboard-item__header-left">
              <p class="dashboard-item__name">{{ $t('account.upline') }}</p>
              <span
                class="dashboard-item__tooltip"
                :content="$t(`account.upline_tooltip`)"
                v-tippy
              >
                <svg-vue icon="info" />
              </span>
            </div>
            <span class="dashboard-item__upline-lvl">
              {{ $t('account.level') }}: {{ parent.activeLevel }}
            </span>
          </header>
          <div class="dashboard-item__flex">
            <div class="dashboard-item__upline-logo">
              <UiAvatar :user="parent" />
            </div>
            <div class="dashboard-item__upline-info">
              <h3 class="dashboard-item__upline-title" v-if="parent.name">
                <span>{{ parent.name }}</span>
                <button
                  class="dashboard__copy-button btn-reset"
                  type="button"
                  @click="handleClipboardCopy(parent.name)"
                >
                  <svg-vue icon="clipboard" class="w-5 h-5" />
                </button>
              </h3>
              <h4 class="dashboard__code">
                <span>{{ uplineStrip }}</span>
                <button
                  class="dashboard__copy-button btn-reset"
                  type="button"
                  @click="handleClipboardCopy(parent.account)"
                >
                  <svg-vue icon="clipboard" class="w-5 h-5" />
                </button>
              </h4>
            </div>
          </div>
        </template>
        <UiLoader v-else theme="block" :loading="true" />
      </div>

      <div class="dashboard-item">
        <header class="dashboard-item__header">
          <p class="dashboard-item__name">{{ $t('account.forceTokens') }}</p>
          <span
            class="dashboard-item__tooltip"
            :content="$t(`account.stable_force_tooltip`)"
            v-tippy
          >
            <svg-vue icon="info" />
          </span>
        </header>
        <div class="dashboard-box__inner">
          <div class="dashboard-box__row">
            <div class="dashboard-box__col">
              <div>
                <h3 class="dashboard-item__title">{{ sfcBalance }} SFCO</h3>
                <div class="dashboard-box__stable">
                  Stable Force Classic Old
                </div>
              </div>
              <div v-if="isMyAccount">
                <span v-if="isSwapped">Swapped</span>

                <ButtonDark v-else @event="swapToSfcNew" text="Swap new SFC" />
              </div>
            </div>
          </div>
          <div class="dashboard-box__row">
            <h3 class="dashboard-box__value dashboard-item__title">
              {{ sfcNewBalance }} SFC
            </h3>
            <div class="dashboard-box__stable">Stable Force Classic</div>
          </div>
          <div class="dashboard-box__row">
            <h3 class="dashboard-box__value dashboard-item__title">
              {{ sfc2Balance }} SFC2
            </h3>
            <div class="dashboard-box__stable">Stable Force Classic v2.0</div>
          </div>
        </div>
      </div>
    </div> -->
  
  </section>
</template>

<script>
import web3 from 'web3'
import { computeStats } from '@/helpers/matrix'
import { copyToClipboard } from '@/helpers/clipboard'
import { mapActions, mapGetters, mapState } from 'vuex'
import { nullEmptyHash } from '@/helpers/matrix'
import { tokenFormatFromWei } from '@/helpers/crypto'
import { isSameAccount } from '@/helpers/user'
import { Log } from '@/helpers/dev'
import Button from '@/components/UI/Button.vue'
import ButtonDark from '@/components//UI/ButtonDark'
import Portfolio from '@/components/Dashboard/Portfolio.vue'
import ForceSystem from '@/components/Dashboard/ForceSystem.vue'
import GuessForceSystem from "@/components/Dashboard/GuessForceSystem.vue"
// import InnerBalance from "@/components/Dashboard/InnerBalance.vue"

export default {
  components: {
    Button,
    ButtonDark,
    Portfolio,
    ForceSystem,
    GuessForceSystem,
    // InnerBalance,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      parent: null,
      sfcBalance: 0,
      sfcNewBalance: 0,
      sfc2Balance: 0,
      address: web3.utils.toChecksumAddress(this.user.account),
      isSwapped: false,
    }
  },
  async mounted() {
    this.getUpline()
    this.getSfcBalance()
    this.getSfcNewBalance()
    this.getSfc2Balance()
    this.checkIsSwapped()
  },
  computed: {
    isMyAccount() {
      return isSameAccount(this.getAccount, this.user.account)
    },
    classicList() {
      return this.classicMatrix
    },
    mySfcBalance() {
      return this.getBalance.sfc
    },
    mySfcNewBalance() {
      return this.getBalance.sfcNew
    },
    mySfc2Balance() {
      return this.getBalance.sfc2
    },
    calcStatsClassic() {
      if (this.user && this.user.classic) {
        return computeStats(this.user.classic)
      }
      return null
    },
    refLink() {
      const code = this.user.name

      return `${window.location.host}/r/${code}`
    },
    addressStrip() {
      return this.sliceString(this.user.account)
    },

    uplineStrip() {
      return this.sliceString(this.parent.account, 6)
    },
    ...mapGetters('crypto', [
      'getMainContract',
      'getParent',
      'getAccount',
      'getBalance',
      'getSFCContract',
      'getSFCNewContract',
      'getSFC2Contract',
    ]),
    ...mapState('user', ['classicMatrix']),
  },
  methods: {
    ...mapActions('crypto', [
      'getProgramLevels',
      'connectContract',
      'swapSfcNew',
      'alreadySwappedSFC',
      'getBalances',
    ]),
    ...mapActions('user', ['getUserByField']),
    sliceString(str, firstNums = 7, start = true) {
      // `${str.slice(0, firstNums)}...${str.slice(-4)}`
      return `${start ? str.slice(0, firstNums) : ''}...${str.slice(-4)}`
    },
    async getUpline() {
      let parent = null
      if (this.isMyAccount) {
        parent = this.getParent
      } else {
        parent = await this.getMainContract.methods
          .parent(this.user.account)
          .call()
      }

      if (parent && nullEmptyHash(parent)) {
        const [lvlErr, levels] = await this.getProgramLevels(parent)
        const lastLevel = levels ? levels.findLast((x) => x.active) : null

        const { users } = await this.getUserByField({ account: parent }).catch(
          console.warn
        )

        this.parent = {
          name: users && users.name,
          avatar: users && users.avatar,
          account: parent,
          activeLevel: lastLevel ? lastLevel.lvl + 1 : 0,
        }
      }
    },
    async getSfcBalance() {
      if (this.isMyAccount) {
        this.sfcBalance = this.mySfcBalance
      } else {
        let balanceSfc = await this.getSFCContract.methods
          .balanceOf(this.user.account)
          .call()
        balanceSfc = tokenFormatFromWei(balanceSfc)
        this.sfcBalance = balanceSfc.toFixed(2)
      }
      Log('this.sfc', this.sfcBalance)
    },
    async getSfcNewBalance() {
      if (this.isMyAccount) {
        this.sfcNewBalance = this.mySfcNewBalance
      } else {
        let balanceSfcNew = await this.getSFCNewContract.methods
          .balanceOf(this.user.account)
          .call()
        balanceSfcNew = tokenFormatFromWei(balanceSfcNew)
        this.sfcNewBalance = balanceSfcNew.toFixed(2)
      }
      Log('this.sfcNew', this.sfcNewBalance)
    },
    async getSfc2Balance() {
      if (this.isMyAccount) {
        this.sfc2Balance = this.mySfc2Balance
      } else {
        let balanceSfc2 = await this.getSFC2Contract.methods
          .balanceOf(this.user.account)
          .call()
        balanceSfc2 = tokenFormatFromWei(balanceSfc2)
        this.sfcNewBalance = balanceSfc2.toFixed(2)
      }
      Log('this.sfcNew', this.sfcNewBalance)
    },
    handleClipboardCopy(text) {
      if (!text) return

      copyToClipboard(text)
        .then(() => {
          this.$toast.success(`${text} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
    swap() {
      this.swapToSfcNew()
    },
    async swapToSfcNew() {
      const [err, responce] = await this.swapSfcNew({
        onBlockchainPending: () => {
          this.$store.commit('loader/showProcessingModal', {
            isShown: true,
            text: `${this.$t(
              'matrix.activationModal.blockchainPendingFirstLine'
            )} <br />
                                ${this.$t(
              'matrix.activationModal.blockchainPendingSecondLine'
            )}`,
          })
        },
      })

      this.$store.commit('loader/showProcessingModal', {
        isShown: false,
      })

      if (err) {
        this.$toast.error(this.$t('errors.swapSfcError'))
      } else {
        await this.getBalances()
      }
    },
    async checkIsSwapped() {
      this.isSwapped = await this.alreadySwappedSFC(this.user.account)
    },
  },
  watch: {
    mySfcBalance(newBalance) {
      Log('sfc balance changed', this.isMyAccount, newBalance)
      if (this.isMyAccount) {
        this.sfcBalance = newBalance
      }
    },
    mySfcNewBalance(newBalance) {
      Log('sfc new balance changed', this.isMyAccount, newBalance)
      if (this.isMyAccount) {
        this.sfcNewBalance = newBalance
      }
    },
    mySfc2Balance(newBalance) {
      Log('sfc2 balance changed', this.isMyAccount, newBalance)
      if (this.isMyAccount) {
        this.sfc2Balance = newBalance
      }
    },
  },
}
</script>

